import { AddressElement, PaymentElement } from '@stripe/react-stripe-js';
import {
	StripeAddressElementChangeEvent,
	StripePaymentElementChangeEvent,
	StripeError,
} from '@stripe/stripe-js';
import debounce from 'lodash/debounce';
import { useState } from 'react';
import { useIntl } from 'react-intl';

import { Loader } from '@calm-web/design-system';

import { useAnalytics } from '@/hooks/analytics/useAnalytics';
import { useShouldShowSelfServeAch } from '@/hooks/useShouldShowSelfServeAch';

import { calculatingSelectedContractedLives } from '../../../utils';
import messages from './messages';
import { Header, Container, StyledHr } from './styles';

interface FormState {
	payment: boolean;
	address: boolean;
}

type StripeElementType = 'payment' | 'address';

async function getTaxValueCalculations(calculateTaxValues: () => Promise<void>): Promise<void> {
	if (calculateTaxValues) await calculateTaxValues();
}

const debounceBillingDetails = debounce(getTaxValueCalculations, 3000);

const StripePaymentElementForm: React.FC<{
	fullName?: string;
	calculateTaxValues?: () => Promise<void>;
	setIsLoadingTaxes?: (isLoading: boolean) => void;
	setShouldShowTaxAmount?: (shouldShowTaxAmount: boolean) => void;
	context?: {
		location: string;
		variant: string;
		companySize: string;
		selectedCoveredLives: number;
	};
}> = ({ calculateTaxValues, setIsLoadingTaxes, setShouldShowTaxAmount, context }) => {
	const { formatMessage } = useIntl();
	const { logEvent } = useAnalytics();
	const [formState, setFormState] = useState<FormState>({ payment: false, address: false });
	const [showSelfServeAch, { loading }] = useShouldShowSelfServeAch();

	const onLoadError = (
		event: { elementType: 'payment' | 'address'; error: StripeError },
		elementType: string,
	): void => {
		logEvent(`Teams : Checkout : ${elementType} : Stripe: Load Error`, {
			error: event.error.message ?? '',
			selectedCoveredLives: context?.selectedCoveredLives ?? '',
			selectedContractedLives: calculatingSelectedContractedLives(context?.selectedCoveredLives ?? 0),
			companySize: context?.companySize ?? '',
			location: context?.location ?? '',
			variant: context?.variant ?? '',
		});
	};

	const handleChange = async (
		event: (StripePaymentElementChangeEvent | StripeAddressElementChangeEvent) & { error?: StripeError },
		elementType: StripeElementType,
	): Promise<void> => {
		if (setShouldShowTaxAmount) setShouldShowTaxAmount(true);
		if (setIsLoadingTaxes) setIsLoadingTaxes(true);
		const { complete } = event;
		setFormState(prev => ({ ...prev, [elementType]: complete }));
		const state = { ...formState, [elementType]: complete };
		const isFormValid = Object.values(state).every(value => value);

		if (isFormValid && calculateTaxValues) debounceBillingDetails(calculateTaxValues)?.catch(() => {});
		else if (setShouldShowTaxAmount) setShouldShowTaxAmount(false);
	};

	if (loading) {
		return (
			<Container>
				<Loader color="gray5" />
			</Container>
		);
	}

	return (
		<Container data-testid="stripe-payment-element-form">
			<Header>
				{showSelfServeAch ? formatMessage(messages.headerWithOptions) : formatMessage(messages.header)}
			</Header>
			<PaymentElement
				options={{
					wallets: { applePay: 'never', googlePay: 'never' },
					terms: { card: 'never' },
				}}
				onLoadError={event => onLoadError(event, 'Payment')}
				onChange={e => handleChange(e, 'payment')}
			/>
			<StyledHr />
			<Header>{formatMessage(messages.billingHeader)}</Header>
			<AddressElement
				options={{
					mode: 'billing',
					// TOODO: Figure out how to prevent validation from
					// triggering on mount after initial submission (submit, leave, come back)
					// defaultValues: {
					// 	name: fullName,
					// },
				}}
				onLoadError={event => onLoadError(event, 'Address')}
				onChange={e => handleChange(e, 'address')}
			/>
		</Container>
	);
};

export default StripePaymentElementForm;
